import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  GlobalState,
  StateMachineProvider,
  createStore,
} from "little-state-machine";

import type { AppProps } from "next/app";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

import { AppState, Auth0Provider } from "@auth0/auth0-react";
import Router from "next/router";

const contact = {
  first_name: "",
  last_name: "",
  email: "",
  communications: false,
};
const address = {
  address_one: "",
  address_two: "",
  city: "",
  postcode: "",
  region: "",
  country: "",
};

const payment = {
  type: "",
  status: "",
  setup_id: "",
  mandate_id: "",
  customer_id: "",
};

const store: GlobalState = {
  personalDetails: { ...contact },

  businessDetails: {
    business_name: "",
    business_phone: "",
    business_gst: "",
    business_address: { ...address },
  },
  locations: [],
  payment: { ...payment },
};

createStore(store);

const theme = extendTheme({
  colors: {
    brand: {
      green: "#44BA7F",
      extralightgrey: "#F7F7F7",
      black: "#000",
      lightblue: "#0072CE1A",
      blue: "#0072CE",
    },
  },
  styles: {
    global: {
      body: {
        bg: "brand.extralightgrey",
        color: "brand.black",
        fontFamily: "'Inter', sans-serif",
      },
    },
  },
  components: {
    Steps,
    Button: {
      variants: {
        secondary: {
          bg: "brand.lightblue",
          color: "brand.blue",
        },
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const onRedirectCallback = (appState?: AppState) => {
    Router.replace(appState?.targetUrl || appState?.returnTo || "/");
  };

  return (
    <ChakraProvider theme={theme}>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
        redirectUri={process.env.NEXT_PUBLIC_AUTH_BASE_URL!}
        audience={process.env.NEXT_PUBLIC_AUTH_AUDIENCE!}
        scope="openid profile email offline_access"
        onRedirectCallback={onRedirectCallback}
      >
        <StateMachineProvider>
          <Component {...pageProps} />
        </StateMachineProvider>
      </Auth0Provider>
    </ChakraProvider>
  );
}

export default MyApp;
